import styled from "@emotion/styled"
import React, { useState } from "react"
import { fonts, device, colors } from "../components/layout/GlobalStyles"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import redArrow from "../images/arrow-red-left.svg"
import greenArrow from "../images/arrow-green-left.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Seo } from "../components/Seo"
import facebook from "../images/logos/facebook-black.svg"
import linkedIn from "../images/logos/linkedin-black.svg"
import link from "../images/logos/link-black.svg"
import { Button } from "../components/layout/StyledComponents"

import {
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from "react-share"
// import { ShareButton } from "react-custom-share"

const TemplateContainer = styled.div`
  background: ${colors.secondary201};
  padding: 72px 0px 72px;
  overflow: hidden;

  .line {
    background: ${colors.secondary302};
    width: 100px;
    position: absolute;
    top: 102px;
    left: -55px;
    height: 3px;
  }
  .button-container {
    p {
      text-align: left;
    }
  }
  .green-line {
    margin: 48px 0px 52px;
    height: 1px;
    width: 2000px;
    background: ${colors.primary2};
  }
  .date {
    font-weight: 800;
    color: ${colors.dark3};
  }
  .body {
    a {
      font-weight: 600;
    }
    a:hover {
      text-decoration: underline;
    }
    ul {
      padding-left: 12px;
    }
    li {
      letter-spacing: -0.69px;
      line-height: 36px;
      font-family: ${fonts.primary};
      font-size: 22px;
      margin-bottom: 8px;
      color: ${colors.dark3};
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.primary2};
      font-family: ${fonts.secondary};
      margin: 0 0px 23px 0px;
      font-size: 40px;
      font-weight: 800;
      letter-spacing: -1.25px;
      line-height: 48px;
    }
  }
  .social-container {
    margin-top: 58px;
    img {
      margin-right: 36px;
      transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    }
    img:hover {
      transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      opacity: 75%;
    }
    h3 {
      margin: 0 0px 23px 0px;
      font-size: 40px;
      font-weight: 800;
      letter-spacing: -1.25px;
      line-height: 48px;
    }
  }
  a {
    color: ${colors.primary2};
    img {
      margin-bottom: 18px;
      width: 36px;
    }
    li {
      margin: 8px 0px;
    }
    p,
    li {
      width: 88px;
      font-size: 22px;
      color: ${colors.secondary302};
      font-weight: 900;
      letter-spacing: -0.69px;
      line-height: 28px;
    }
  }
  h1 {
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -1.56px;
    line-height: 42px;
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    margin: 0 0 32px 0px;
  }
  p {
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
    margin: 0 0 16px 0px;
  }
  h1 + div {
    margin: 0 0 32px 0px;
  }
  .main-image {
    img {
      border-radius: 15px;
    }
  }

  @media ${device.laptop} {
    padding: 188px 0px 129px;
    .line {
      background: ${colors.secondary302};
      width: 1800px;
      position: absolute;
      top: 24px;
      left: -1805px;
      height: 2px;
    }
    .green-line {
      margin: 48px 0px 52px;
    }
    .social-container {
      position: relative;
      bottom: 50px;
      right: 60px;
      h3 {
        left: 60px;
        position: relative;
      }
      div {
        padding-left: 80px;
        position: relative;
        border-left: 1px solid ${colors.dark3};
        left: 60px;
      }
    }
    .main-image {
      img {
        height: 582px;
        width: 922px;
      }
    }
    .date {
      font-weight: 800;
      color: ${colors.dark3};
      margin-bottom: 6px;
    }
    .body {
      width: 637px;
    }
    .body-container {
      position: relative;
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
    .button-container {
      height: 170px;
      display: flex;
      align-items: start;
      transition: 0.2s ease-in;
      width: 16.66%;

      transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
      img {
        width: 22px;
        transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        position: relative;
        right: 30px;
        top: 5px;
      }
      p {
        text-align: left;
      }
      &:hover {
        img {
          position: relative;
          right: 40px;
        }
        p {
          color: ${colors.primary2};
        }
      }
    }
    .flex-container {
      justify-content: space-between;
      display: flex;
    }
    .text-container {
      width: 922px;
    }
    h1 {
      font-size: 50px;
      line-height: 55px;
      font-family: ${fonts.secondary};
      color: ${colors.primary2};
    }
  }
  @media ${device.laptop} {
    .button-container {
      position: relative;
      right: 10px;
    }
  }
`

const Social = styled.div`
  display: flex;
  img {
    cursor: pointer;
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    max-width: 122px;
    height: 170px;
    img {
      background: none;
      position: relative;
      right: 10px;
      border-radius: none;
      margin-bottom: 45px;
    }
    img:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ArticlesTemplate = ({
  data,
  data: {
    allMarkdownRemark: {
      html,
      frontmatter: {
        title,
        date,
        button_text,
        button_link,
        description,
        slug,
        featured_image,
        categories,
      },
    },
  },
}) => {
  const [hovered, setHovered] = useState(false)

  const reformattedString = date.toString().slice(0, 10)
  const month = reformattedString.slice(5, 7)
  let day = reformattedString.slice(8)

  if (day[0] === "0") {
    day = day.slice(-1)
  }
  const year = reformattedString.slice(0, 4)
  let arr = []
  const categoriesInit = categories.map(category => {
    let words = category.split(" ")
    let final = words.map(word => {
      return `${word[0].toUpperCase()}${word.slice(1, category.length)}`
    })
    arr.push(final.join(" "))
    return
  })
  const categoriesText = arr.join(" • ")
  const monthRender = month => {
    if (month === "01") {
      return "Jan"
    }
    if (month === "02") {
      return "Feb"
    }
    if (month === "03") {
      return "Mar"
    }
    if (month === "04") {
      return "Apr"
    }
    if (month === "05") {
      return "May"
    }
    if (month === "06") {
      return "Jun"
    }
    if (month === "07") {
      return "Jul"
    }
    if (month === "08") {
      return "Aug"
    }
    if (month === "09") {
      return "Sep"
    }
    if (month === "10") {
      return "Oct"
    }
    if (month === "11") {
      return "Nov"
    }
    if (month === "12") {
      return "Dec"
    }
  }
  return (
    <>
      <Seo title={title} description={description} />
      <TemplateContainer>
        <div className="container">
          <div className="flex-container">
            <button
              style={{ background: "none", border: "none" }}
              onMouseOver={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              className="button-container"
            >
              <div className="line"></div>
              <Link to="/news-events/">
                {hovered ? (
                  <img
                    className="small-arrow"
                    src={greenArrow}
                    alt="open-menu"
                  />
                ) : (
                  <img className="small-arrow" src={redArrow} alt="open-menu" />
                )}
                <p>All News & Events</p>
              </Link>
            </button>
            <div className="text-container">
              <h1>{title}</h1>
              <GatsbyImage
                className="main-image"
                image={getImage(featured_image.childrenImageSharp[0])}
              />
              <div className="green-line"></div>
              <p className="date">
                {`${monthRender(
                  reformattedString.slice(5, 7)
                )} ${day}, ${year}`}{" "}
                | {categoriesText}
              </p>
              <div className="body-container">
                <div className="body">
                  <div
                    className="body"
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>{" "}
                  {button_text && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={button_link && button_link}
                    >
                      <Button
                        style={{ marginTop: "28px" }}
                        className="article-button"
                        color="yellow"
                      >
                        {button_text}
                      </Button>
                    </a>
                  )}
                </div>
                <div className="social-container">
                  <h3>Share</h3>
                  <Social>
                    <FacebookShareButton
                      url={`https://www.mlifefoundation.org${slug}`}
                    >
                      <img src={facebook} alt="facebook logo" />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={`https://www.mlifefoundation.org${slug}`}
                    >
                      <img src={linkedIn} alt="linkedin logo" />
                    </LinkedinShareButton>
                    {/* <ShareButton> */}
                    <EmailShareButton
                      subject={`MLIFE Foundation - ${title}`}
                      body={`Check out this article - ${description}`}
                      url={`https://www.mlifefoundation.org${slug}`}
                      onClick={() => {}}
                      openShareDialogOnClick={true}
                    >
                      <img
                        // onClick={() =>
                        //   navigator.clipboard.writeText(
                        //     `https://www.mlifefoundation.org${slug}`
                        //   )
                        // }
                        src={link}
                        alt="link logo"
                      />
                    </EmailShareButton>
                    {/* </ShareButton> */}
                  </Social>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TemplateContainer>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    allMarkdownRemark: markdownRemark(id: { eq: $id }) {
      html
      id
      frontmatter {
        title
        description
        slug
        button_link
        button_text
        date
        author
        categories
        featured_image {
          childrenImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE)
          }
        }
      }
    }
  }
`

export default ArticlesTemplate
